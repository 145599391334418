<template slot="global">
  <modal :show.sync="showModal" headerClasses="justify-content-center" :required="true" >
    <h4 slot="header" class="title title-up">
      {{ $t('updateprice') }}
    </h4>
    <div :class="`alert-danger`" class="alert alert-dismissible fade show" v-if="modal_alert">
      <button type="button" aria-hidden="true" class="close" data-dismiss="alert" aria-label="Close" @click.prevent="modal_alert = false">
        <i class="nc-icon nc-simple-remove"></i>
      </button>
      <span>{{ modal_alert_msg }}</span>
    </div>
    <div class="row">
      <div class="col-sm-8 mx-auto">
        <span style="display: flex;">
          <el-tooltip :content="helper_public_pricetxt" :open-delay="100" placement="top" popper-class="adjust-width">
            <i class="nc-icon nc-alert-circle-i info-icon info-t20 info-l0"></i>
          </el-tooltip>
          <el-select class="select-default form-control" v-model="aidprice" :placeholder="public_pricetxt" style="margin-bottom: 10px;border: 0;">
            <el-option
              class="select-default"
              v-for="p in percentage"
              :key="p.prop"
              :label="p.label"
              :value="p.prop"
              >
            </el-option>
          </el-select>
        </span>
      </div>
    </div>
    <template slot="footer">
      <div class="py-3 mx-auto" v-if="modalinprogress">
        <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda;"></i>
      </div>
      <div class="left-side" v-if="modalinprogress === false">
        <p-button @click.prevent="confirmUpPrice" link>{{ $t('upentrybtn') }}</p-button>
      </div>
      <div class="divider" v-if="modalinprogress === false"></div>
      <div class="right-side" v-if="modalinprogress === false">
        <p-button type="danger" @click.prevent="closeModalPrice" link>{{ $t('cancel') }}</p-button>
      </div>
    </template>
  </modal>
</template>

<script>

import {Tooltip} from "element-ui";
import {Modal} from "src/components/UIComponents";

export default {
  name: 'FollowersUpdatePrice',
  components: {
    [Tooltip.name]: Tooltip,
    Modal,
  },
  props: {
    follower: {
        type: Object,
    },
    showModal: {
      type: Boolean,
    },
  },
  data() {
    return {
        aid: null,
        aidprice: null,
        modal_alert: false,
        modal_alert_msg: null,
        modal_alert_type: 'danger',
        modal_is_editing: false,
        helper_public_pricetxt: this.$t('helper_public_pricetxt'),
        helper_fixedfee: this.$t('helper_fixedfee'),
        public_pricetxt: this.$t('public_pricetxt'),
        modalinprogress: false,
        percentage: [
          {'prop': 0.00, 'label': '0%'},
          {'prop': 0.05, 'label': '5%'},
          {'prop': 0.10, 'label': '10%'},
          {'prop': 0.15, 'label': '15%'},
          {'prop': 0.20, 'label': '20%'},
          {'prop': 0.25, 'label': '25%'},
          {'prop': 0.30, 'label': '30%'},
          {'prop': 0.35, 'label': '35%'},
          {'prop': 0.40, 'label': '40%'},
          {'prop': 0.45, 'label': '45%'},
          {'prop': 0.50, 'label': '50%'},
        ],
    }
  },
  methods: {
    editPrice(follower) {
      this.aid = follower.id;
      this.strat_id = follower.strat_id;
      this.aidprice = parseFloat(follower.price);
    },
    confirmUpPrice() {
      this.modalinprogress = true;
      const pjson = {
        "price": this.aidprice,
      };
      this.$upPrice(pjson, this.strat_id, this.aid)
        .then(this.successUpPriceHandler, this.errorUpPriceHandler);
    },
    successUpPriceHandler(resp) {
      if (!resp.success) {
        this.$toast.warning(this.$t(resp.msg))
        this.modalinprogress = false;
        return
      }
      this.$toast.success(this.$t('priceupdated'))
      this.closeModalPrice();
      this.$emit('success-up-price-handler');
    },
    errorUpPriceHandler(resp) {
      this.modalinprogress = false;
      if (resp.data.msg) {
        this.$toast.error(this.$t(resp.data.msg))
      }
    },
    closeModalPrice() {
      this.modalinprogress = false;
      this.$emit('on-close-modal-price')
    },
  },
  watch: {
    follower: {
      handler(val, oldVal) {
        if (val != null) {
          this.editPrice(val);
        }
      },
      deep: true
    },
  },
  created(){
    this.$setTranslatingInProgress()
  },
  beforeDestroy(){
    this.$root.$off("showTranslatingProgress")
  },
}
</script>
